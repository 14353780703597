<template>
  <div class="py-4 text-center">
    <div class="kit__utils__avatar kit__utils__avatar--rounded kit__utils__avatar--size84 border border-5 border-white d-inline-block mb-3">
      <img src="resources/images/avatars/3.jpg" alt="Lee Aniston" />
    </div>
    <div class="font-weight-bold font-size-24 text-dark">Lee Aniston</div>
    <div class="font-size-18 text-gray-6 mb-4 mt-n1">Project Manager</div>
    <a href="#" class="btn btn-success">Add User</a>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral23v1',
}
</script>

<template>
  <div class="text-white font-weight-bold">
    <div class="font-size-21 mb-2">{{ data.payroll.name }}</div>
    <div class="d-flex align-items-end flex-wrap">
      <div class="pr-3 mr-auto">
        <i class="fa fa-money font-size-48" />
      </div>
      <div class="font-size-36 mb-n2">{{ data.amount }} kr</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral24',
  props: ['data'],
}
</script>
